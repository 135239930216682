import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import investorPayDetails from './investorPayDetails';
// import { format } from 'date-fns';
export default {
  name: 'investorPayment',
  components: {
    DatePicker,
    investorPayDetails
  },
  watch: {
    currentPage: function() {
      this.getInvPaymentList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvPaymentList();
    }
  },
  data() {
    return {
      loader: false,
      legalEntity: {
        value: null,
        text: null
      },
      project: {
        value: null,
        text: null
      },
      fromDate: null,
      toDate: null,
      editMode: false,
      investorName: null,
      tenantName: null,
      tenantLease: null,
      investorLease: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      vsetCode: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      trxAmt: 0,
      amtPaid: 0,
      sumOfInvoice: 0,
      sumOfAmount: 0,
      sumOfUnpaid: 0,
      invPaymentList: [],
      invPaymentFields: [
        {
          key: 'lease_tenant_no',
          class: 'col-fix'
        },
        {
          key: 'tenant_area',
          class: 'col-fix'
        },
        {
          key: 'tenant_rate',
          class: 'col-fix'
        },
        {
          key: 'customer_name'
        },
        {
          key: 'trx_amt',
          label: 'Tenant Invoice Amount'
        },
        {
          key: 'amt_paid_against_trx',
          label: 'Tenant Amt Received Against Inv.'
        },
        {
          key: 'trx_line_amount'
        },
        {
          key: 'investor_bill_amount_without_gst'
        },
        {
          key: 'sum_of_inv_invoice_amt',
          label: 'Investor Inv. Amount'
        },
        {
          key: 'sum_of_amount_paid',
          label: 'Amt. Paid Investor'
        },
        {
          key: 'sum_of_unpaid_amt'
        }
      ],
      showInvPayDetailModal: false,
      invPayRowDetail: null,
      params: { fromDate: null, toDate: null },
      isViewMore: false,
      cusName: null,
      leaseTenAggNo: null,
      leaseInvAggNo: null,
      trxLineAmt: 0,
      invBillAmtWtGst: 0
    };
  },
  mounted() {},
  methods: {
    getInvPaymentList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.legalEntity.value,
        project: this.project.value,
        from_date: this.fromDate,
        to_date: this.toDate,
        inv_name: this.investorName,
        inv_lease: this.investorLease,
        tenant_name: this.tenantName,
        tenant_lease: this.tenantLease,
        customer_name: this.cusName,
        lease_tenant_agreement_no: this.leaseTenAggNo,
        lease_investor_agreement_no: this.leaseInvAggNo
      };
      this.loader = true;
      this.$store
        .dispatch('leaseInvestorPayment/getInvPaymentList', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.isViewMore = false;
            this.invPaymentList = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }

          this.calculateAmount();
        })
        .catch(() => {
          this.loader = false;
        });
    },
    calculateAmount() {
      this.trxLineAmt = 0;
      this.invBillAmtWtGst = 0;
      this.trxAmt = 0;
      this.amtPaid = 0;
      this.sumOfInvoice = 0;
      this.sumOfAmount = 0;
      this.sumOfUnpaid = 0;
      for (let i of this.invPaymentList) {
        this.trxLineAmt = this.trxLineAmt + i.trx_line_amount;
        this.invBillAmtWtGst =
          this.invBillAmtWtGst + i.investor_bill_amount_without_gst;
        this.trxAmt = this.trxAmt + i.trx_amt;
        this.amtPaid = this.amtPaid + i.amt_paid_against_trx;
        this.sumOfInvoice = this.sumOfInvoice + i.sum_of_inv_invoice_amt;
        this.sumOfAmount = this.sumOfAmount + i.sum_of_amount_paid;
        this.sumOfUnpaid = this.sumOfUnpaid + i.sum_of_unpaid_amt;
      }
      this.trxLineAmt = this.trxLineAmt.toFixed(2);
      this.invBillAmtWtGst = this.invBillAmtWtGst.toFixed(2);
      this.trxAmt = this.trxAmt.toFixed(2);
      this.amtPaid = this.amtPaid.toFixed(2);
      this.sumOfInvoice = this.sumOfInvoice.toFixed(2);
      this.sumOfAmount = this.sumOfAmount.toFixed(2);
      this.sumOfUnpaid = this.sumOfUnpaid.toFixed(2);
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.project.text = item.value_code;
        this.project.value = item.value_set_dtl_id;
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === 'LEGAL_ENTITY_LIST') {
        this.legalEntity.text = null;
        this.legalEntity.value = null;
      } else if (vsetCode === 'LEASE_PROJECT_LIST') {
        this.project.text = null;
        this.project.value = null;
      }
    },
    clearSearchFilters() {
      this.legalEntity = {
        value: null,
        text: null
      };
      this.project.value = null;
      this.project.text = null;
      this.fromDate = null;
      this.toDate = null;
      this.investorName = null;
      this.investorLease = null;
      this.tenantLease = null;
      this.tenantName = null;
      this.invPaymentList = [];
      this.totalRows = null;
      this.currentPage = 1;
      this.cusName = null;
      this.leaseTenAggNo = null;
      this.leaseInvAggNo = null;
      this.isViewMore = false;
    },
    rowSelected(item) {
      this.invPayRowDetail = item;
      this.params = {
        fromDate: this.fromDate,
        toDate: this.toDate
      };
      this.showHideInvPayDetailModal(true);
    },
    showHideInvPayDetailModal(flag) {
      this.showInvPayDetailModal = flag;
    }
  }
};
