import commonHelper from '@/app/utility/common.helper.utility';
export default {
  components: {},
  watch: {
    currentPage: function() {
      this.getInvPayDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getInvPayDetails();
    }
  },
  props: ['invPayRowDetail', 'params'],
  data() {
    return {
      loader: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      investorPayDetailsData: [],
      investorPayDetailsFields: [
        {
          key: 'lease_investor_agreement_no',
          label: 'Lease Investor Agg. no.'
        },
        {
          key: 'lease_fms_sub_unit_name',
          label: 'Sub Unit'
        },
        {
          key: 'lease_description'
        },
        {
          key: 'investor_from_date'
        },
        {
          key: 'investor_to_date'
        },
        {
          key: 'lease_schedule_type_vset_meaning',
          label: 'Schedule Type'
        },
        {
          key: 'investor_rate'
        },
        {
          key: 'investor_area'
        },
        {
          key: 'vendor_name',
          label: 'Vendor'
        },
        {
          key: 'sharing_ratio'
        },
        {
          key: 'invoice_num'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'investor_invoice_amt'
        },
        {
          key: 'amount_paid',
          label: 'Paid Amt'
        },
        {
          key: 'investor_unpaid_amt',
          label: 'Unpaid Amt'
        }
      ]
    };
  },
  mounted() {
    if (this.invPayRowDetail) {
      this.getInvPayDetails();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update') {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    getInvPayDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        lease_tenant_agreement_no: this.invPayRowDetail.lease_tenant_no,
        from_date: this.params.fromDate,
        to_date: this.params.toDate
      };
      this.loader = true;
      this.$store
        .dispatch('leaseInvestorPayment/getInvPayDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.investorPayDetailsData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {}
};
